export const create_UUID = () => {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const addPx = value => {
  if (value == null) return value;
  if (typeof value === "number") return value + "px";
  if (typeof value === "string" && !value.trim().endsWith("px")) {
    // Check if the string is numeric (optionally with decimals)
    // and then append "px". If it's not numeric (like textTransform), we leave it.
    if (/^\d+(\.\d+)?$/.test(value.trim())) {
      return value.trim() + "px";
    }
  }
  return value;
};
